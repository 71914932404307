import { GTMEvent } from './GTMEvent'

export const pageView = (
  pageType: string,
  pageCategory?: string,
  language?: string,
  loggedIn?: boolean,
): GTMEvent[] => [
  {
    pageType,
    pageCategory,
    language,
    loggedIn: loggedIn ? 'yes' : 'no',
  },
]
