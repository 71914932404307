import Head from 'next/head'
import React from 'react'

interface Props {
  metaTitle?: string
  metaDescription?: string
}

/**
 * Component to add meta data in <head>
 */
const Meta = ({ metaTitle, metaDescription }: Props) => (
  <Head>
    {metaTitle && (
      <>
        <title>{metaTitle}</title>
        <meta property="og:title" content={metaTitle} />
      </>
    )}

    {metaDescription && <meta name="description" content={metaDescription} />}
  </Head>
)

export default Meta
