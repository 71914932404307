export const localeLinguiMap = new Map<string, string>([
  ['global', 'en-US'],
  ['int_en', 'en'],
  ['dk', 'da-DK'],
  ['at', 'de-AT'],
  ['de', 'de-DE'],
  ['nl', 'nl-NL'],
  ['be_nl', 'nl-BE'],
  ['be_fr', 'fr-BE'],
  ['fr', 'fr-FR'],
  ['uk', 'en-GB'],
  ['ie', 'en-IE'],
  ['us', 'en-US'],
  ['int_es', 'es'],
  ['se', 'sv-SE'],
  ['no', 'nb-NO'],
  ['it', 'it-IT'],
  ['cz', 'cs-CZ'],
  ['ch_de', 'de-CH'],
  ['pl', 'pl-PL'],
  ['es', 'es-ES'],
])
