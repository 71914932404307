import getCatalog from '@catalogs'
import { i18n } from '@lingui/core'
import { useEffect } from 'react'

import { localeLinguiMap } from './localeLinguiMap'
import useLocale from './useLocale'

/**
 * Hook that keeps the current loaded catalog up-to-date with the route.
 * @param locale
 */
export const useUpdateCatalogs = () => {
  const siteLocale = useLocale()
  const locale = localeLinguiMap.get(siteLocale) ?? siteLocale
  const { locale: catalogLocale } = getCatalog(locale)

  // The locale might change due to a navigation event.
  useEffect(() => {
    // If the locale is the same as the pre-rendered catalog, we can switch to
    // it instantly without having to import it again.
    if (locale === catalogLocale) {
      i18n.activate(locale)

      return
    }
    // If a new locale is requested, we need to import it first.
    import(`../locales/${locale}/messages`).then((catalog) => {
      i18n.load({
        [locale]: catalog.messages,
      })
      i18n.activate(locale)
    })
  }, [catalogLocale, locale])
}

export default useUpdateCatalogs
