export const getCraftSites = (locale?: string) => {
  const activeStoreView = locale ?? 'nl'

  switch (activeStoreView) {
    case 'int_es':
      return ['b2c_es', 'default']
    case 'int_en':
      return ['default']
    case 'es':
      return ['b2c_es_es', 'b2c_es', 'default']
    case 'ch_de':
      return ['b2c_ch_de', 'b2c_de', 'default']
    default:
      return [`b2c_${activeStoreView}`, 'default']
  }
}

interface CraftSiteProps {
  preferSites: string[]
  site: string[]
}

export function getCraftSiteProps(locale?: string): CraftSiteProps {
  const sites = getCraftSites(locale)

  return {
    preferSites: sites?.slice(0, 1) ?? [],
    site: sites ?? [],
  }
}
